.paginateContainer {
    display: flex;
    padding-left: 4px;
    padding-right: 80px;
}

.listContainer {
    background-color: white;
    border: 1px solid #AAB2C0;
    border-radius: 2.85px;
    color: #AAB2C0;
    cursor: pointer;
    font-family: Lato;
    font-size: 15px;
    font-style: normal;
    font-weight: 400;
    letter-spacing: 0px;
    line-height: 18px;
    list-style: none;
    margin-right: 3px;
    text-align: right;
}

.pageLink {
    display: block;
    outline: none;
    padding: 5px 10px;
}

.arrow {
    align-self: center;
    border: 0px;
    color: #F58158;
    display: inline-block;
}

.previousArrow {
    margin-right: 13px;
}

.nextArrow {
    margin-left: 10px;
}

.activePage {
    background-color: #F58158;
    border-color: #F58158;
    color: white;
}

.breakContainer {
    align-self: center;
    color: #AAB2C0;
    cursor: pointer;
    display: inline;
    list-style: none;
    margin-right: 3px;
}

.breakLink {
    outline: none;
}